import * as React from "react";
import * as Article from "@elevio/kb-kit/lib/toolkit/article";

import PageLayout from "../components/layouts/Page";
import Header from "../components/Header";
import Footer from "../components/Footer";

import WithAsideLayout, {
  Aside,
  Main,
  MainHeader,
  MainBody,
} from "../components/layouts/WithAside";
import CategoryList from "../components/CategoryList";
import ArticleList from "../components/ArticleList";
import Breadcrumb from "../components/PageBreadcrumbs";
import SearchInput from "../components/SearchInput";

import ArticleIcons from "../components/ArticleIcons";
import ArticleFeedback from "../components/ArticleFeedback";
import { MainHeading } from "@elevio/styles/lib/components/text";

const Page = () => (
  <PageLayout header={<Header />} footer={<Footer />}>
    <WithAsideLayout>
      <Aside>
        <SearchInput placeholder="Search" className="mb-12 hidden md:block" />
        <CategoryList />
        <ArticleList />
      </Aside>

      <Main>
        <MainHeader className="pt-16">
          <ArticleIcons />
          <Breadcrumb className="mb-6" />
          <MainHeading className="mb-4">
            <Article.Title />
          </MainHeading>
        </MainHeader>

        <MainBody>
          <Article.Body className="mb-12" />
          <ArticleFeedback className="mb-6" />
        </MainBody>

        {/* <MainFooter>
          <MoreHelp />
        </MainFooter> */}
      </Main>
    </WithAsideLayout>
  </PageLayout>
);

export default Page;
