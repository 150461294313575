import * as React from "react";
import cx from "classnames";
import {
  HeaderTitle,
  MediumHeading,
  BodySmall,
} from "@elevio/styles/lib/components/text";
import { ExtraPageLink } from "@elevio/kb-kit/lib/toolkit/meta";
import Icon from "@elevio/styles/lib/components/icons";
import { ClickableCard } from "@elevio/styles/lib/components/cards";
import { addClassName } from "@elevio/styles/lib/utils/classes";

import * as Categories from "@elevio/kb-kit/lib/toolkit/categories";
import PageLayout from "../components/layouts/Page";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  MainSection,
  GridContainer,
  GridItem,
} from "../components/layouts/Main";
import CategoryCard from "../components/CategoryCard";
import SearchInput from "../components/SearchInput";

const HomePage = () => (
  <PageLayout
    header={
      <Header hideSearch>
        <HeaderTitle className="text-center mb-4 text-white">
          Code Enforcement Portal
        </HeaderTitle>
        <SearchInput placeholder="Search our customer portal..." />
      </Header>
    }
    footer={<Footer />}
  >
    <MainSection>
      <GridContainer>
        <GridItem>
          <ExtraPageLink path="report-an-issue" className="flex flex-1">
            <ClickableCard
              className={cx(addClassName("category-card"), "text-center")}
            >
              <div className="p-6 text-center" data-testid="category-link">
                <Icon icon="error" size="large" className="mx-auto mb-6" />

                <MediumHeading className="mb-2">
                  Report Issue / Ask Question
                </MediumHeading>

                <BodySmall className="mb-4">
                  Click here to start a submission
                </BodySmall>
              </div>
            </ClickableCard>
          </ExtraPageLink>
        </GridItem>
        <Categories.Loop>
          <GridItem>
            <CategoryCard />
          </GridItem>
        </Categories.Loop>
      </GridContainer>
    </MainSection>
  </PageLayout>
);

export default HomePage;
