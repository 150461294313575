import React from "react";
import cx from "classnames";
import * as Meta from "@elevio/kb-kit/lib/toolkit/meta";
import * as Globals from "@elevio/kb-kit/lib/utils/globals";

type Props = {
  className?: string;
};

export const Logo: React.FC<Props> = ({ children, className, ...props }) => (
  <Meta.HomeLink className={cx("block", className)}>
    <h1 className="text-3xl">
      <img src={Globals.getAssetURL("logo.png")} className="w-24" />
    </h1>
  </Meta.HomeLink>
);
